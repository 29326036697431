import { useParams } from "react-router-dom";
import { NotionPage } from "./notionPage";

const IframePage = ({
  pageURL,
  pathName,
}: {
  pageURL?: string;
  pathName?: string;
}) => {
  const { pageId } = useParams();
  const finalPageId = pageURL || pageId;

  return (
      <div className="flex items-center justify-center z-0">
        <NotionPage pageId={finalPageId || ''} pathName={pathName || ''} />
      </div>
  );
};

export default IframePage;
