export type ToastState = {
    toast: {
        type: 'none' | 'error' | 'warning' | 'success' | 'info',
        message: string,
        error: Error | null,
        fatal: boolean
    };
};

export const initState: ToastState = {
    toast: {
        type: 'none',
        message: '',
        error: null,
        fatal: false
    }
};
