const isProduction = process.env.NODE_ENV === 'production';

interface Logger {
  log: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
  info: (...args: any[]) => void;
  debug: (...args: any[]) => void;
}

const logger: Logger = {
  log: (...args: any[]): void => {
    if (!isProduction) console.log(...args);
  },
  warn: (...args: any[]): void => console.warn(...args),
  error: (...args: any[]): void => console.error(...args),
  info: (...args: any[]): void => {
    if (!isProduction) console.info(...args);
  },
  debug: (...args: any[]): void => {
    if (!isProduction) console.debug(...args);
  },
};

export default logger;

// Augment the global namespace
declare global {
  var logger: Logger;
}

global.logger = logger;