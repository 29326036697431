import React, { useRef } from 'react';
import { useScroll, useTransform, motion } from 'framer-motion';

interface ScrollYMotionProps {
    children: React.ReactNode;
    startY?: number;
    endY?: number;
    className?: string;
}

const ScrollYMotion: React.FC<ScrollYMotionProps> = ({ children, startY = 0, endY = 100, className }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "end start"]
    });

    const y = useTransform(scrollYProgress, [0, 1], [startY, endY]);

    return (
        <div ref={ref} className={`absolute top-0 w-full h-full justify-center items-center ${className}`}>
            <motion.div style={{ y }} className=''>
                {children}
            </motion.div>
        </div>
    );
};

export default ScrollYMotion;
