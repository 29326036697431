import React, { useState } from 'react';
import Button from '../../utils/components/grapes/atoms/button/Button';
import { scrollToSection } from '../../utils/scrollUtils';
import Logo from '../../assets/images/logo/Logo-Dark.svg';
import ReactGA from 'react-ga4';

const MobileHeader: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, sectionId: string) => {
        e.preventDefault();
        setIsOpen(false);
        scrollToSection(sectionId);
    };

    const handleCTAClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        window.open("https://calendar.app.google/wh2nBk9Md4JnEMgd7", "_blank");
        ReactGA.send({ hitType: "event", category: "Button", action: "Click", label: "Demo" });
    };

    return (
        <header className="w-full z-50 bg-pf-white shadow-md">
            <div className="flex justify-between items-center px-4 py-3">
                <div className="logo-placeholder">
                    {/* Add logo image here */}
                    <img src={Logo} alt="Logo placeholder" className="h-8" />
                </div>
                <div className="flex items-center space-x-4">
                    <Button variant="primary" size="sm">
                        Use PathFinder
                    </Button>
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="text-pf-black focus:outline-none z-50"
                    >
                        {isOpen ? (
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        ) : (
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        )}
                    </button>
                </div>

            </div>
            <div className={`fixed inset-0 bg-pf-white z-40 flex flex-col justify-center items-center transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <nav className="text-center">
                    <ul className="space-y-6">
                        <li><a href="#benefits" onClick={(e) => handleNavClick(e, 'benefits')} className="text-2xl font-semibold">Benefits</a></li>
                        <li><a href="#how-it-works" onClick={(e) => handleNavClick(e, 'how-it-works')} className="text-2xl font-semibold">How It Works</a></li>
                        <li><a href="#testimonials" onClick={(e) => handleNavClick(e, 'testimonials')} className="text-2xl font-semibold">Testimonials</a></li>
                        <li><a href="#press" onClick={(e) => handleNavClick(e, 'press')} className="text-2xl font-semibold">Press</a></li>
                    </ul>
                </nav>
                <div className="p-4 w-full mt-10">
                    <div>
                        <Button variant="primary" size="lg" className="w-full mb-4" onClick={handleCTAClick}>
                            Book a Demo
                        </Button>
                    </div>
                    <Button variant="secondary" size="lg" className="w-full">Book a Demo</Button>
                </div>
            </div>
        </header>
    );
};

export default MobileHeader;
