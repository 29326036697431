import React, { useEffect, useState } from "react";
// import { NotionRenderer } from "react-notion";
// import { BlockMap } from "notion-types";
// import "react-notion-x/src/styles.css";

import axios from "axios";
import { ExtendedRecordMap } from "notion-types";
import { getPageTitle } from "notion-utils";
import { NotionRenderer } from "react-notion-x";
import "react-notion-x/src/styles.css";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const NotionPage = ({
  pageId,
  pathName,
}: {
  pageId: string;
  pathName?: string;
}) => {
  const navigate = useNavigate();
  const [recordMap, setRecordMap] = useState<ExtendedRecordMap | null>(null);
  const fetchNotionPage = async () => {
    // Get the block map for the page
    // const response = await axios.get(
    //     "http://127.0.0.1:8081/thepathfinderprojectbackendapi/us-central1/getNotionPage?pageId=d6e7cc466bc9451bb271e2239c6cbf50"
    // );
    let response: any = null;
    try {
      response = await axios.get(
        `https://pathfinder-notion-web-content.vercel.app/api/${pageId}`
      );
    } catch (error) {
      navigate("/error");
      return;
    }

    if (response.data.error) {
      console.error("Error:", response.data.error.message);
      navigate("/error");
    } else {
      setRecordMap(response.data.recordMap);
    }
  };

  useEffect(() => {
    // console.log("In a notion page");
    fetchNotionPage();
  }, [pageId]);

  if (!recordMap) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const title = getPageTitle(recordMap);

  return (
    <>
      <div className="relative w-full mx-auto px-4 z-0">
        <NotionRenderer
          recordMap={recordMap}
          fullPage={true}
          darkMode={false}
          rootPageId={pageId}
          mapPageUrl={(pageId, recordMap) =>
            pathName ? `/${pathName}/${pageId}` : `/path/${pageId}`
          }
        />
      </div>
    </>
  );
};
