import React from 'react';
import * as Icons from 'solar-icon-set'
import './DashboardCard.css';

export type DashboardCardProps = {
    studentName: string;
    project: string;
    needsHelp: boolean;
    className?: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ studentName, project, needsHelp, className }) => {
    return (
        <div className={`dashboard-card ${className} ${needsHelp ? '!border-pf-daylight-S2 border-4 border-b-[6px]' : ''}`}>
            <div className="dashboard-card-row">
                <span className="dashboard-card-name">{studentName}</span>
                <span className="dashboard-card-project">{project}</span>
                {needsHelp ? <Icons.ShieldWarning size={24} color='#E69D0C' /> : <div className='w-[24px] h-[24px]' />}
            </div>
        </div>
    )
}

export default DashboardCard;
