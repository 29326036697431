import React, { useState, useRef } from 'react';
import Header from '../../components/Header/Header';
import MobileHeader from '../../components/Header/MobileHeader';
import heroBg from '../../assets/images/bg-hero.png';
import Footer from '../../sections/Footer';
const ThankYouPage: React.FC = () => {

return (
    <div className="flex flex-col min-h-screen bg-pf-sketchbook">
        <div className="sticky top-0 z-50">
            <div className="hidden md:block">
                <Header />
            </div>
            <div className="block md:hidden">
                <MobileHeader />
            </div>
        </div>
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 w-full">
            <img src={heroBg} alt="hero-bg" className="absolute top-0 left-0 w-full h-full object-cover" />
            <section className="h-[40vh] min-h-[calc(92vh)] w-full flex flex-col items-center justify-center">
            <div className="text-center items-center mt-[-60px] relative">
                <h1 className='mb-12 text-display-medium md:text-display-large'>
                    Thank you
                </h1>
                <h2 className='mb-8 w-full max-w-[1000px] text-heading-small md:text-heading-medium mx-auto'>
                    We'll be in touch soon
                </h2>
            </div>
        </section>
        </div>
        {/* <CTA /> */}
        <Footer />
    </div>
);
};

export default ThankYouPage;