import React, { useRef, useState, useEffect } from 'react';
import TestimonialCard from '../utils/components/grapes/atoms/testimonialCard/TestimonialCard';
import Button from '../utils/components/grapes/atoms/button/Button';
import * as Icons from 'solar-icon-set';
import websiteCopy from '../data/websiteCopy.json';

const cardColors = [
    'bg-pf-ember-T3 border-pf-ember',
    'bg-pf-daylight-T3 border-pf-daylight',
    'bg-pf-sky-T3 border-pf-sky',
    'bg-pf-moss-T3 border-pf-moss',
    // 'bg-pf-rose-T3 border-pf-rose',
    // 'bg-pf-emerald-T3 border-pf-emerald',
];

const Testimonials: React.FC = () => {
    const [index, setIndex] = useState(0);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = (direction: 'left' | 'right') => {
        setIndex(prev => {
            const newIndex = direction === 'left'
                ? Math.max(0, prev - 1)
                : Math.min(websiteCopy.testimonials.items.length - 1, prev + 1);
            return newIndex;
        });
    };

    return (
        <section id="testimonials" className="w-full bg-pf-sketchbook flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center w-full max-w-[1400px] space-y-8 mb-20 ">
                <h2 className="flex text-center">{websiteCopy.testimonials.title}</h2>
                <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                    <h3 className="text-center">{websiteCopy.testimonials.subtitle}</h3>
                    <a href={websiteCopy.footer.social.items.find(item => item.label === "Facebook")?.href} target="_blank" rel="noopener noreferrer" className="inline-flex items-center justify-center p-4 bg-[#1877F2] text-white rounded-full hover:bg-[#166FE5] transition-colors duration-300">
                        <span className="mr-2 text-headline-small">{websiteCopy.testimonials.facebookButton}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#FFFFFF">
                            <path d="M12 2C6.477 2 2 6.477 2 12c0 5.013 3.693 9.153 8.505 9.876V14.65H8.031v-2.629h2.474v-1.749c0-2.896 1.411-4.167 3.818-4.167 1.153 0 1.762.085 2.051.124v2.294h-1.642c-1.022 0-1.379.969-1.379 2.061v1.437h2.995l-.406 2.629h-2.588v7.247C18.235 21.236 22 17.062 22 12c0-5.523-4.477-10-10-10z" />
                        </svg>
                    </a>
                </div>
            </div>
            <div className="flex relative mx-auto px-16 justify-center items-center w-full">
                <Button
                    variant="tertiary"
                    size="sm"
                    onClick={() => handleScroll('left')}
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 !bg-transparent !p-0"
                    disabled={index <= 0}
                >
                    <Icons.RoundArrowLeft iconStyle="Bold" size={48} />
                </Button>
                <div
                    ref={scrollContainerRef}
                    className="flex overflow-x-hidden snap-x snap-mandatory"
                    style={{ scrollSnapType: 'x mandatory' }}
                >
                    {websiteCopy.testimonials.items.map((testimonial, i) => (
                        <div
                            key={i}
                            className="flex-shrink-0 w-full sm:w-1/2 px-4 snap-start transition-transform duration-300 ease-in-out"
                            style={{ transform: `translateX(-${index * 100}%)` }}
                        >
                            <TestimonialCard
                                {...testimonial}
                                className={`h-64 ${cardColors[i % cardColors.length]}`}
                            />
                        </div>
                    ))}
                </div>
                <Button
                    variant="tertiary"
                    size="sm"
                    onClick={() => handleScroll('right')}
                    disabled={index >= websiteCopy.testimonials.items.length - 1}
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 !bg-transparent !p-0"
                >
                    <Icons.RoundArrowRight iconStyle="Bold" size={48} />
                </Button>
            </div>
        </section>
    );
};

export default Testimonials;
