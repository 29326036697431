import { useScroll, useTransform, motion } from 'framer-motion';
import React, { useRef } from 'react';
import './VideoCard.css';

export type VideoCardProps = {
    title: string;
    className?: string;
    img_src: string;
    // videoImg: string;
}

const VideoCard: React.FC<VideoCardProps> = ({ title, className, img_src }) => {

    return (
        <div className={`video-card ${className}`}>
            <div className='video-card-video'
                style={{ backgroundImage: `url(/images/videos/${img_src}.webp)`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >

            </div>
            <p className='text-heading-small px-2 py-2'>{title}</p>
        </div>
    )
}

export default VideoCard;       