// src/pages/Home/index.tsx
import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../sections/Footer";
import MobileHeader from "../../components/Header/MobileHeader";
import HeroSection from "./components/HeroSection";
import VideoSection from "./components/VideoSection";
import ExplainerSection from "./components/ExplainerSection";
import BannerCard from "./components/BannerCard";
import HowItWorks from "../../sections/HowItWorks";
import Benefits from "../../sections/Benefits";
import Testimonials from "../../sections/Testimonials";

const HomePage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-pf-sketchbook">
      <div className="sticky top-0 z-50">
        <div className="hidden md:block">
          <Header />
        </div>
        <div className="block md:hidden">
          <MobileHeader />
        </div>
      </div>
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <div className="flex flex-col items-center justify-center w-full max-w-full overflow-x-hidden my-8 sm:my-12 space-y-[4rem]">
          <HeroSection />
          <VideoSection />
          {/* <ExplainerSection /> */}
          <div className="max-w-[1440px] flex-col space-y-[4rem] mx-auto px-4 sm:px-6 lg:px-8 w-full">
            <Benefits />
            <Testimonials />
            <HowItWorks />
          </div>
          <div className="hidden sm:block w-full">
            {/* <BannerCard /> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;