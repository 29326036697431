import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "../index";
import { ToastState, initState } from "./state";

export const toastSlice = createSlice({
    initialState: initState,
    name: "toast",
    reducers: {
        setToast(state, action: Action<ToastState["toast"]>) {
            return {
                ...state,
                toast: action.payload,
            };
        },

        reset(state) {
            return {
                toast: initState.toast
            }
        }
    },
});
