import React, { useEffect, useState } from 'react';
import Button from '../../utils/components/grapes/atoms/button/Button';
import { scrollToSection } from '../../utils/scrollUtils';
import LogoWordmark from '../../assets/images/logo/Logo-Wordmark-Dark.svg';
import websiteCopy from '../../data/websiteCopy.json';
import ReactGA from 'react-ga4';

interface HeaderProps {
    button?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ button }) => {
    const [top, setTop] = useState(true);

    useEffect(() => {
        const scrollHandler = () => {
            window.scrollY > 50 ? setTop(false) : setTop(true)
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, sectionId: string) => {
        e.preventDefault();
        scrollToSection(sectionId);
    };

    return (
        <header className={`w-full flex items-center justify-between px-4 py-2 ${!top ? 'shadow-md bg-pf-white' : 'bg-pf-sketchbook'}`}>
            <div className="logo-placeholder">
                <a href="#">
                    <img src={LogoWordmark} alt="PathFinder Logo" className="h-8 w-auto bg-pf-white" />
                </a>
            </div>
            <div className="flex space-x-10 items-center">
                {/* <nav>
                    <ul className="flex space-x-6">
                        {websiteCopy.header.navItems.map((item, index) => (
                            <li key={index}>
                                <a href={item.href} onClick={(e) => handleNavClick(e, item.href.slice(1))}>
                                    {item.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav> */}
                <div className="space-x-4 flex items-end">
                    {button || (
                        <Button variant="primary" onClick={() => {
                            window.open("https://calendar.app.google/wh2nBk9Md4JnEMgd7", "_blank");
                            ReactGA.send({ hitType: "event", category: "Button", action: "Click", label: "Demo" });
                        }} size="md">{websiteCopy.header.buttons.demo}</Button>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
