import React, { ButtonHTMLAttributes } from 'react';
import './button.css';
import '../../../../styles/responsive-text.css';
import { PulseLoader } from 'react-spinners';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'error';
type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant: ButtonVariant;
    size?: ButtonSize;
    label?: string;
    loading?: boolean;
    icon?: React.ReactNode;
    iconPosition?: 'left' | 'right';
}

const Button: React.FC<ButtonProps> = ({
    variant,
    size = 'md',
    loading = false,
    icon,
    iconPosition = 'left',
    className,
    children,
    ...props
}) => {
    const baseClasses = 'button';
    const variantClasses = `button--${variant}`;
    const sizeClasses = `button--${size}`;
    const loadingClasses = loading ? 'button--loading' : '';
    // const iconClasses = icon ? `button--icon-${iconPosition}` : '';

    const colorMap = {
        primary: '#FFFFFF',
        secondary: '#FFBA31', // pf-primary color
        tertiary: '#12121A', // pf-black color
        error: '#FFFFFF'
    };

    return (
        <button
            className={`${baseClasses} ${variantClasses} ${sizeClasses} ${loadingClasses} ${className}`}
            disabled={props.disabled}
            {...props}
        >
            {loading && (
                <PulseLoader size={8} color={colorMap[variant]} cssOverride={{ position: 'absolute' }} />
            )}
            <span className={`button__text `}>{children}</span>
        </button>
    );
};

export default Button;