import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";
import './utils/styles/responsive-text.css';
import ComponentGallery from './pages/ComponentGallery';
import Main from "./pages/Main/Main";
import Home from "./pages/Home";
import IframePage from "./pages/Iframe";
import TagManager from 'react-gtm-module';
import ThankYouPage from "./pages/ThankYou/thankyou";

const landingPageVariants = [{
  endpoint: "s1",
  title: "Safety-first internet for kids",
  description: "Pathfinder removes bad content before kids even see it. It won’t show up on YouTube, Google and other webpages."
}, {
  endpoint: "s2",
  title: "Keep your Kids safe Online",
  description: "Pathfinder filters unsafe content and guides kids to educational, age-appropriate resources."
}, {
  endpoint: "sm1",
  title: "Safe Browsing for your Kids",
  description: "Pathfinder removes bad content before kids even see it. It won’t show up on YouTube, Google and other webpages."
}, {
  endpoint: "sh1",
  title: "Safety you can trust for your Child",
  description: "Give your kids the freedom to learn online without worrying about their safety. Pathfinder filters out bad content so they never see it."
}, {
  endpoint: "tm2",
  title: "Simplified Learning for Kids",
  description: "Pathfinder makes digitals tools simpler for kids to work on projects. We blur our distractions and highlight what’s important in the moment they need help."
}, {
  endpoint: "tm3",
  title: "Project tools made easier for Elementary kids",
  description: "Pathfinder simplifies complex tools like Scratch or Canva so your kid can focus on learning and building their projects."
}, {
  endpoint: "tm4",
  title: "Project tools made easier for Middleschoolers",
  description: "Pathfinder simplifies complex tools like Scratch or Canva so your kid can focus on learning and building their projects."
}, {
  endpoint: "i1",
  title: "Tailored Internet for Kids",
  description: "Pathfinder adapts the internet to your child’s pace, offering guided help as they explore the internet safely. "
}, {
  endpoint: "ih2",
  title: "A personalised Internet for your child",
  description: "Pathfinder provides personalized suggestions and on-screen help to support your child using the internet."
}, {
  endpoint: "ih3",
  title: "Turn your Kid’s interests into Projects",
  description: "Pathfinder helps kids dive into project-based learning with easy-to-use tools."
}, {
  endpoint: "sh4",
  title: "Safe Search Engine for Homeschoolers",
  description: "Pathfinder removes bad content before kids even see it. It won’t show up on YouTube, Google and other webpages."
}, {
  endpoint: "sm5",
  title: "Safe Search Engine for Microschools",
  description: "Pathfinder removes bad content before kids even see it. It won’t show up on YouTube, Google and other webpages."
}, {
  endpoint: "ih5",
  title: "The Internet designed for Homeschoolers",
  description: "Pathfinder ensures safe, tailored learning for your child, adapted to your family values."
}, {
  endpoint: "im6",
  title: "The Internet designed for Microschools",
  description: "Pathfinder helps your learners navigate and explore the internet. It’s a helper in their chromebooks."
}];


function App() {
    useEffect(() => {
        // Initialize GTM
        TagManager.initialize({
            gtmId: 'GTM-PMWLTXNH'
        });
    }, []);

    return (
        <Router>
            <div>
                <Routes>
                    {process.env.NODE_ENV === 'development' && (
                        <Route path="/component-gallery" element={<ComponentGallery />} />
                    )}
                    
                    {/* Map through landing page variants */}
                    {landingPageVariants.map(({ endpoint, title, description }) => (
                        <Route 
                            key={endpoint}
                            path={`/${endpoint}`}
                            element={<Main title={title} description={description} />}
                        />
                    ))}

                    {/* Keep existing routes */}
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/terms-and-conditions"
                        element={<IframePage pageURL={"f7ec797b20564a2c88f6ffce0f61409e"} />}
                    />
                    <Route
                        path="/privacy-policy"
                        element={<IframePage pageURL={"72dd48768286472b9ce8264aa97d7d26"} />}
                    />
                    <Route
                        path="/cookies-policy"
                        element={<IframePage pageURL={"27c4a08c23f9432f934f9fa3a9e6d26b"} />}
                    />
                    <Route
                        path="/thankyou"
                        element={<ThankYouPage />}
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
