import React, { useRef } from 'react';
import StyledBox from '../utils/components/grapes/atoms/styledBox/StyledBox';
import Lottie from "lottie-react";
import lott from '../assets/lottie/Flow 1.json'
import scaffold from '../assets/lottie/scaffold.json'
import search from '../assets/lottie/search.json'
import tools from '../assets/lottie/tools.json'
import socratic from '../assets/lottie/socratic.json'
import voice from '../assets/lottie/voice.json'
// import websiteCopy from '../data/websiteCopy.json';

const Benefits: React.FC = () => {

    const websiteCopy = {
        "benefits": {
            "title": "Benefits",
            "items": [
                {
                    "title": "Socratic Guide",
                    "description": "AI companion for learners, available anytime, anywhere",
                    "className": "bg-pf-daylight border-pf-daylight-S2",
                    "lottie": socratic
                },
                {
                    "title": "Safe Internet Search",
                    "description": "Curated, age-appropriate content from across the web",
                    "className": "bg-pf-ember border-pf-ember-S2",
                    "lottie": search
                },
                {
                    "title": "Project Scaffolding",
                    "description": "End-to-end support for project planning and execution",
                    "className": "bg-pf-emerald-T3 border-pf-emerald-S2",
                    "lottie": scaffold
                },
                {
                    "title": "Voice Interactions",
                    "description": "Engage in voice-to-voice conversations with the AI guide",
                    "className": "bg-pf-rose-T3 border-pf-rose-S2",
                    "lottie": voice
                },
                {
                    "title": "Tool Discovery",
                    "description": "Introduction to new, free educational tools and their usage",
                    "className": "bg-pf-sky border-pf-sky-S2",
                    "lottie": tools
                }
            ]
        },
    }

    // Create an array of refs for each item
    const lottieRefs = useRef(websiteCopy.benefits.items.map(() => React.createRef()));

    const handleMouseEnter = (index: number) => {
        //@ts-ignore
        lottieRefs.current[index].current?.play();
    };

    const handleMouseLeave = (index: number) => {
        //@ts-ignore
        lottieRefs.current[index].current?.stop();
    };

    return (
        <section id="benefits" className="w-full flex flex-col items-center justify-center px-4 max-w-[1100px] mx-auto">
            <h2 className='text-display-medium mb-20'>{websiteCopy.benefits.title}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-[1100px] mx-auto">
                {websiteCopy.benefits.items.map((item, index) => (
                    <StyledBox key={index} className={`${index === 0 ? 'row-span-2' : ''} ${item.className} gap-y-4 !px-0`}
                        //@ts-ignore
                        onMouseEnter={() => handleMouseEnter(index)}
                        //@ts-ignore
                        onMouseLeave={() => handleMouseLeave(index)}
                    >
                        <div
                            className={index !== 0 ? 'aspect-[5/3] overflow-hidden px-0' : 'flex items-center justify-end h-full w-full px-0 py-4'}
                        >
                            {/* @ts-ignore */}
                            {item.lottie && <Lottie lottieRef={lottieRefs.current[index]} animationData={item.lottie} autoplay={false} />}
                        </div>
                        <div className='p-4'>
                            <h4>{item.title}</h4>
                            <p>{item.description}</p>
                        </div>
                    </StyledBox>
                ))}
            </div>
        </section>
    );
};

export default Benefits;
