import React, { useState, useRef } from 'react';
import Button from '../utils/components/grapes/atoms/button/Button';
import websiteCopy from '../data/websiteCopy.json';
import Projects from './Projects';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import emailjs from '@emailjs/browser';
import * as Icons from 'solar-icon-set';
// import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';

interface HeroProps {
    title?: string;
    description?: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
}

interface FormDetails {
    from_name: string;
    from_email: string;
    kids_age: string;
    schooling_type: string;
}

const Hero: React.FC<HeroProps> = ({ title, description, showModal, setShowModal }) => {
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSent, setIsSent] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const form = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();

    const formSchema = Yup.object().shape({
        from_name: Yup.string().required("Name is required"),
        from_email: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),
        kids_age: Yup.string().required("Age of kids is required"),
        schooling_type: Yup.string().required("Type of schooling is required"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormDetails>({
        mode: "onSubmit",
        resolver: yupResolver(formSchema),
    });

    const sendEmail = (data: FormDetails) => {
        setIsSubmitting(true);
        
        // Get values from the form
        const formData = new FormData(form.current as HTMLFormElement);
        
        // Create a new form element for sending
        const sendForm = document.createElement('form');
        
        // Add the name and email fields
        sendForm.innerHTML = `
            <input name="from_name" value="${formData.get('from_name')}" />
            <input name="from_email" value="${formData.get('from_email')}" />
            <textarea name="message">Hi, I ${formData.get('schooling_type')} my kids and they are ages ${formData.get('kids_age')}. The landing page I saw was ${title} and the description was ${description}.</textarea>
        `;

        // Track form submission in Mixpanel
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Form Submitted', {
                endpoint: window.location.pathname.substring(1),
                title,
                description,
                name: data.from_name,
                email: data.from_email,
                kids_age: data.kids_age,
                schooling_type: data.schooling_type
            });
        }

        emailjs
            .sendForm(
                "service_lnffz7b",
                "template_8n44unm",
                sendForm,
                "1mrtCcpYjeR_RckcO"
            )
            .then(
                (result) => {
                    setIsSent(true);
                    setIsSubmitting(false);
                    setShowModal(false);
                    reset();
                    
                    // Track conversion using TagManager
                    // TagManager.dataLayer({
                    //     dataLayer: {
                    //         event: 'conversion',
                    //         'send_to': 'AW-16783651576/wC9qCOXIkO8ZEPjliMM-',
                    //         'value': 1.0,
                    //         'currency': 'USD'
                    //     }
                    // });
                    navigate('/thankyou');
                },
                (error) => {
                    setShowError(true);
                    setErrorMessage(error.text);
                }
            );
    };

    const handleSignUpClick = () => {
        // Track button click in Mixpanel
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Sign Up Button Clicked', {
                endpoint: window.location.pathname.substring(1),
                title,
                description
            });
        }

        // // Track button click conversion
        // TagManager.dataLayer({
        //     dataLayer: {
        //         event: 'conversion',
        //         'send_to': 'AW-16783651576/hajwCIrRkO8ZEPjliMM-',
        //         'value': 1.0,
        //         'currency': 'USD'
        //     }
        // });
        
        // Open the modal
        setShowModal(true);
    };

    return (
        <section className="h-[40vh] min-h-[calc(92vh)] w-full flex flex-col items-center justify-center">
            <div className="text-center items-center mt-[-60px] relative">
                <h1 className='mb-12 text-display-medium md:text-display-large'>
                    {title || websiteCopy.hero.title}
                </h1>
                <h2 className='mb-8 w-full max-w-[1000px] text-heading-small md:text-heading-medium mx-auto'>
                    {description || websiteCopy.hero.subtitle}
                </h2>
                <div className="flex items-center justify-between w-full max-w-[1000px] mt-[4rem] mx-auto">
                    <div className="flex-1 space-y-4 ml-[64px] items-center">
                        <p>Launching in Jan 2025</p>
                        <div>
                            <Button 
                                variant="primary"
                                size="lg"
                                onClick={handleSignUpClick}
                            >
                                Try it for free
                            </Button>
                        </div>
                    </div>
                    <img 
                        src="/images/assets/NMC-Logo.png" 
                        alt="hero-image" 
                        className="w-32 h-32 object-cover rotate-12 shadow-xl rounded-full flex-shrink-0" 
                    />
                </div>
            </div>

            <div className={`absolute top-0 left-0 inset-0 z-50 flex items-center justify-center ${showModal ? 'visible' : 'invisible'}`}>
                <div className="fixed inset-0 bg-black opacity-50" onClick={() => setShowModal(false)}></div>
                <div className="bg-white rounded-md shadow-xmdl z-10 pt-6 px-6 max-w-2xl w-full mx-4">
                    <div className="flex justify-between items-center mt-[-0.5rem] mr-[-1rem] mb-2">
                        <h2 className="text-2xl font-bold">Sign Up</h2>
                        <button className='cursor-pointer px-2 py-2'>
                            <Icons.CloseCircle 
                                iconStyle='Bold' 
                                size={24} 
                                onClick={() => setShowModal(false)} 
                            />
                        </button>
                    </div>
                    <div className="mt-[1rem] mb-6">
                        <form ref={form} onSubmit={handleSubmit(sendEmail)} className="flex flex-col gap-4">
                            {showError && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                    <span className="block sm:inline">{errorMessage}</span>
                                </div>
                            )}

                            <div>
                                <label htmlFor="from_name" className="block text-sm font-medium mb-1">Your name</label>
                                <input
                                    id="from_name"
                                    placeholder="Name"
                                    {...register("from_name")}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pf-primary-T1"
                                />
                                {errors.from_name && (
                                    <p className="text-red-500 text-sm">{errors.from_name.message}</p>
                                )}
                            </div>

                            <div>
                                <label htmlFor="from_email" className="block text-sm font-medium mb-1">Your email</label>
                                <input
                                    id="from_email"
                                    type="email"
                                    placeholder="Email"
                                    {...register("from_email")}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pf-primary-T1"
                                />
                                {errors.from_email && (
                                    <p className="text-red-500 text-sm">{errors.from_email.message}</p>
                                )}
                            </div>

                            <div>
                                <label htmlFor="kids_age" className="block text-sm font-medium mb-1">Your kids' ages</label>
                                <input
                                    id="kids_age"
                                    placeholder="e.g., 8, 10, 12"
                                    {...register("kids_age")}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pf-primary-T1"
                                />
                                {errors.kids_age && (
                                    <p className="text-red-500 text-sm">{errors.kids_age.message}</p>
                                )}
                            </div>

                            <div>
                                <label htmlFor="schooling_type" className="block text-sm font-medium mb-1">Type of schooling</label>
                                <input
                                    id="schooling_type"
                                    placeholder="e.g. Homeschool, Microschool, Public School etc."
                                    {...register("schooling_type")}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pf-primary-T1"
                                />
                                {errors.schooling_type && (
                                    <p className="text-red-500 text-sm">{errors.schooling_type.message}</p>
                                )}
                            </div>

                            <div className="flex justify-end mt-4">
                                <Button
                                    variant="primary"
                                    size="sm"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;