import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { toastSlice } from "./slice";
import { initState, ToastState } from "./state";

export const useToast = () => {
    const state = useSelector((state: RootState) => state.toast)
    const dispatch = useDispatch();

    const actions = bindActionCreators(toastSlice.actions, dispatch);

    return { state, actions, initState };
}

export type { ToastState };