export const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        // const yOffset = -8 * rootFontSize; // Adjust this value based on your header height (8rem)
        const yOffset = 0
        console.log("yOffset:", yOffset);
        const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }
};
