import React from "react";
import Button from '../../../utils/components/grapes/atoms/button/Button';
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import * as Icons from "solar-icon-set";
import HeroCircle from "../../../assets/images/hero-circle.png";

const useResponsiveFontSize = () => {
  const getFontSize = () => {
    if (window.innerWidth >= 1024) {
      return "4.5rem";
    } else if (window.innerWidth <= 1024 && window.innerWidth >= 826) {
      return "3.5rem";
    } else if (window.innerWidth <= 825 && window.innerWidth >= 650) {
      return "3rem";
    } else if (window.innerWidth <= 650 && window.innerWidth >= 400) {
      return "2.75rem";
    } else {
      return "2.25rem";
    }
  };

  const [fontSize, setFontSize] = useState(getFontSize());

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return fontSize;
};

const HeroSection = () => {
  let navigate = useNavigate();
  const [currentText, setCurrentText] = useState("Microschools");
  const rotatingTexts = [
    "Microschools",
    "Co-ops", 
    "Teachers",
    "Parents",
    "Pods",
  ];
  const fontSize = useResponsiveFontSize();
  const isScreenSmall = window.innerWidth < 615;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentText((prevText) => {
        const currentIndex = rotatingTexts.indexOf(prevText);
        if (currentIndex === rotatingTexts.length - 1) {
          return rotatingTexts[0];
        } else {
          return rotatingTexts[currentIndex + 1];
        }
      });
    }, 3000); // Change text every 2 seconds

    return () => clearInterval(intervalId); // Clean up on component unmount
  }, []);

  return (
    <div className="flex flex-col space-y-8 md:space-y-16 text-center items-center justify-center">
      <div className="md:mt-[2rem] w-full">
        <h1
          style={{
            lineHeight: "1.5", // Adjust line-height to reduce spacing
            fontSize: fontSize,
            margin: 0, // Remove default margin
          }}
        >
          <span className="text-pf-daylight">{currentText}</span> use
          {isScreenSmall ? "" : " PathFinder to"}
        </h1>
        {isScreenSmall && (
          <h1
            style={{
              lineHeight: "1.5", // Adjust line-height to reduce spacing
              fontSize: fontSize,
              margin: 0, // Remove default margin
            }}
          >
            PathFinder to
          </h1>
        )}
        <h1
          style={{
            lineHeight: "2", // Adjust line-height to reduce spacing
            fontSize: fontSize,
            position: "relative",
            display: "inline-block",
            zIndex: 2,
            margin: 0, // Remove default margin
            verticalAlign: "top", // Align to the top to reduce spacing due to baseline alignment
          }}
        >
          <span>spark the curiosity</span>
          <img
            src={HeroCircle}
            alt="Decorative circle"
            className="absolute z-0"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "calc(110%)",
              height: "auto",
              maxWidth: "none",
            }}
          />
        </h1>
        <h1
          style={{
            lineHeight: "1.5", // Adjust line-height to reduce spacing
            fontSize: fontSize,
            margin: 0, // Remove default margin
          }}
        >
          of their learners
        </h1>
      </div>
      <h4 className="mt-8 px-8 lg:px-0 lg:w-4/5 xs:my-5 text-lg md:text-xl lg:text-2xl">
        PathFinder is the easiest way to implement project based and self
        directed learning. You can have 20 learners doing 20 different things.
        All you have to do is facilitate the lab.
      </h4>
      <div className="static mt-6 flex flex-col sm:flex-row gap-4 md:gap-8">
        <Button
          variant="primary"
          size="md"
          onClick={() => {
            ReactGA.event({
              action: "join_waitlist",
              category: "landing_card",
            });
            window.open(
              "https://www.facebook.com/groups/pathfinderai/",
              "_blank"
            );
          }}
        >
          <div className="flex space-x-3 w-full items-center justify-center">
            <span className="md:text-2xl font-bold">Join our</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="h-6 w-6 md:h-8 md:w-8 text-home-card-4 fill-current"
            >
              <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
            </svg>
            <span className="md:text-2xl font-bold">group</span>
          </div>
        </Button>
        <Button
          variant="secondary"
          size="md"
          onClick={() => {
            ReactGA.event({
              action: "demo",
              category: "hero",
            });
            window.open("https://calendar.app.google/wh2nBk9Md4JnEMgd7", "_blank");
          }}
        >
          <div className="flex space-x-3 items-center justify-center">
            <span className="md:text-2xl font-bold">Book a demo</span>
            <Icons.ArrowRightUp size={24} />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default HeroSection;
