import React, { useState } from 'react';
import websiteCopy from '../data/websiteCopy.json';
import { motion, AnimatePresence } from 'framer-motion';
import ScrollYMotion from '../utils/components/grapes/atoms/motion/ScrollYMotion';
import VideoCard from '../utils/components/grapes/molecules/cards/VideoCard/VideoCard';
import ChatCard from '../utils/components/grapes/molecules/cards/ChatCard/ChatCard';
import DashboardCard from '../utils/components/grapes/molecules/cards/DashboardCard/DashboardCard';

const howItWorksItems = websiteCopy.how_it_works.items.map((item, index) => ({
    id: index + 1,
    text: item,
    color: `bg-pf-${['daylight', 'ember', 'sky'][index]}`
}));

const HowItWorks: React.FC = () => {
    const [activeItem, setActiveItem] = useState(howItWorksItems[0].id);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setActiveItem((prevActiveItem) => {
                const nextIndex = (prevActiveItem % 3);
                return howItWorksItems[nextIndex].id;
            });
        }, 8000);

        return () => clearInterval(interval);
    }, []);

    return (
        <section id="how-it-works" className="max-w-[1100px] mx-auto py-16 flex flex-col items-center justify-center">
            <h2 className="text-center mb-12">{websiteCopy.how_it_works.title}</h2>
            <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 md:space-x-8 w-full">
                <div className="w-full md:w-1/2 h-full flex flex-col justify-between space-y-4 md:space-y-12">
                    {howItWorksItems.map((item) => (
                        <button
                            key={item.id}
                            className={`w-full text-left p-4 rounded-lg transition-colors duration-300 ${activeItem === item.id ? 'bg-pf-daylight-T3' : 'hover:bg-pf-daylight-T2'}`}
                            onClick={() => setActiveItem(item.id)}
                        >
                            <h3>{item.text}</h3>
                        </button>
                    ))}
                </div>
                <div className="w-full md:w-1/2 rounded-lg overflow-hidden relative aspect-square">
                    <Socratic active={activeItem === 1} />
                    <Resources active={activeItem === 2} />
                    <Loop active={activeItem === 3} />
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;

const Socratic: React.FC<{ active: boolean }> = ({ active }) => {
    const { patchMessages, userMessages } = websiteCopy.how_it_works.socratic;
    return (
        <div
            className={`absolute top-0 left-0 w-full h-full flex items-center justify-center transition-opacity bg-white duration-1000 ${active ? 'opacity-100' : 'opacity-0'}`}
        >
            <ScrollYMotion startY={100} endY={200} className='h-full w-full px-8 py-16 justify-center items-end'>
                <ChatCard message={patchMessages[0]} className='!bg-pf-gray-T3 shadow-xl !w-4/5' />
            </ScrollYMotion>
            <div className='h-full w-full px-8 py-16 gap-y-8 flex flex-col justify-center items-end'>
                <ChatCard message={userMessages[0]} />
                <div className='h-48 w-full'></div>
                <ChatCard message={userMessages[1]} />
            </div>
        </div>
    );
};

const Resources: React.FC<{ active: boolean }> = ({ active }) => {
    const { videos } = websiteCopy.how_it_works.resources;
    return (
        <div className={`absolute top-0 left-0 w-full h-full flex justify-center items-center transition-opacity duration-1000 bg-pf-emerald ${active ? 'opacity-100' : 'opacity-0'}`}>
            <ScrollYMotion startY={50} endY={200} className='w-full px-8'>
                <VideoCard title={videos[1].title} img_src={videos[1].img_src} className='shadow-xl !bg-pf-gray-T3' />
            </ScrollYMotion>
            <div className='bg-pf-white w-2/3 p-2 gap-y-4 flex flex-col'>
                {videos.map((video, index) => (
                    <VideoCard key={index} title={video.title} img_src={video.img_src} />
                ))}
            </div>
        </div>
    );
};

const Loop: React.FC<{ active: boolean }> = ({ active }) => {
    const { students } = websiteCopy.how_it_works.loop;
    return (
        <div className={`absolute top-0 left-0 w-full h-full flex justify-center items-center transition-opacity duration-1000 bg-pf-sky ${active ? 'opacity-100' : 'opacity-0'}`}>
            <ScrollYMotion startY={200} endY={275} className='w-full px-4'>
                <DashboardCard studentName={students[0].name} project={students[0].project} needsHelp={students[0].needsHelp} className='shadow-xl !bg-pf-gray-T3' />
            </ScrollYMotion>
            <div className='bg-pf-sky w-10/12 justify-center h-full p-2 gap-y-2 flex flex-col'>
                {students.slice(1).map((student, index) => (
                    student.name === "" ? (
                        <div key={index} className="h-20 w-full"></div>
                    ) : (
                        <DashboardCard key={index} studentName={student.name} project={student.project} needsHelp={student.needsHelp} />
                    )
                ))}
            </div>
        </div>
    );
};
