import React, { ReactNode } from 'react';
import './styledBox.css';

interface StyledBoxProps {
    children: ReactNode;
    className?: string;
}

const StyledBox: React.FC<StyledBoxProps & React.HTMLAttributes<HTMLDivElement>> = ({
    children,
    className = '',
    ...props
}) => {
    return (
        <div
            className={`styled-box ${className}`}
            onClick={props.onClick}
            {...props}
        >
            {children}
        </div>
    );
};

export default StyledBox;
