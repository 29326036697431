import React from 'react';
import Image from '../utils/components/grapes/atoms/image/Image';
import LogoWordmark from '../assets/images/logo/Logo-Wordmark-Light.svg';
import websiteCopy from '../data/websiteCopy.json';

const Footer: React.FC = () => {
    return (
        <footer className="bg-pf-pine text-white py-16 px-4">
            <div className="container mx-auto max-w-[1100px]">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <a href="#">
                            <img src={LogoWordmark} alt="Pathfinder Logo" className="h-8 w-auto mb-4" />
                        </a>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-4">{websiteCopy.footer.about.title}</h3>
                        <ul className="space-y-2">
                            {websiteCopy.footer.about.items.map((item, index) => (
                                <li key={index}><a href={item.href} className="hover:text-pf-primary-T1">{item.label}</a></li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-4">{websiteCopy.footer.social.title}</h3>
                        <ul className="space-y-2">
                            {websiteCopy.footer.social.items.map((item, index) => (
                                <li key={index}><a href={item.href} className="hover:text-pf-primary-T1">{item.label}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="mt-12 pt-8 border-t border-pf-white">
                    <p>{websiteCopy.footer.copyright}</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
