import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useToast } from './export';
import { toastSlice } from './slice';

const Toast: React.FC<PropsWithChildren> = ({ children }) => {
    const { state, actions } = useToast();
    const [isVisible, setIsVisible] = useState(false);
    const [fadeIn, setFadeIn] = useState(true);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (state.toast.type !== 'none') {
            setFadeIn(true)
            setIsVisible(true);
            timer = setTimeout(() => {
                setFadeIn(false)
                setIsVisible(false);
                setTimeout(() => {
                    actions.reset();
                }, 300); // Wait for fade out before resetting
            }, 2000);
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [state.toast]);

    if (state.toast.type === 'none') {
        return <>
            {children}
        </>;
    }

    const bgColor = state.toast.type === 'error' ? 'bg-red-500' : 'bg-green-500';
    const opacityClass = isVisible ? 'opacity-100' : 'opacity-0';
    const fadeInClass = fadeIn ? 'duration-0' : 'duration-1000';


    return (
        <>
            {children}
            <div className={`fixed bottom-4 right-4 p-4 z-50 rounded-md text-white ${bgColor} transition-opacity ease-[cubic-bezier(0,0,0.2,1)] ${fadeInClass} ${opacityClass}`}>
                <p>{state.toast.message}</p>
            </div>
        </>
    );
};

export default Toast