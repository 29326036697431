import mixpanel from 'mixpanel-browser';
import { PropsWithChildren } from 'react';

export const MixpanelInit = (props: PropsWithChildren) => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
            debug: false,
            track_pageview: true,
            ignore_dnt: true,
            persistence: 'localStorage',
            record_sessions_percent: 100,
            record_block_selector: "",
            record_mask_text_selector: ".sensitive"
        });
    } else {
        logger.warn('Mixpanel token is not defined');
    }

    return (
        <>
            {props.children}
        </>
    )
};