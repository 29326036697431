import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initState } from "./state";

export const sessionSlice = createSlice({
    initialState: initState,
    name: "session",
    reducers: {

        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },

        reset() {
            return {
                ...initState
            }
        }
    },
});
