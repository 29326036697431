import React from 'react';
import './testimonialCard.css';

interface TestimonialCardProps {
    quote: string;
    author: string;
    role: string;
    avatarSrc: string;
    className?: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ quote, author, role, avatarSrc, className }) => {

    return (
        <div className={`${className} testimonial-card `}>
            <div className="testimonial-author">
                <div className="testimonial-avatar" style={{ backgroundImage: `url(/images/testimonials/${avatarSrc}.jpeg)`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
                <div className="hidden xl:block">
                    <p className="testimonial-name">{author}</p>
                    <p className="testimonial-role">{role}</p>
                </div>
            </div>
            <div className="flex flex-1 flex-col justify-center">
                <p className="testimonial-quote">{'"' + quote + '"'}</p>
            </div>
            <div className="block xl:hidden">
                <p className="testimonial-name">{author}</p>
                <p className="testimonial-role">{role}</p>
            </div>
        </div>
    );
};

export default TestimonialCard;
