import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./utils/init/reduxInit";
import { PersistGate } from "redux-persist/integration/react";
import Toast from "./utils/components/ducks/toast/Toast";
import './utils/Logger';
import CookieConsent, {
    Cookies,
    getCookieConsentValue,
} from "react-cookie-consent";
import ReactGA from "react-ga4";
// import TagManager from 'react-gtm-module';
import { MixpanelInit } from "./utils/init/mixpanelInit";

const GA_TRACKING_ID = "G-1VCJW9KZX5";
const GTM_ID = "AW-16783651576";

// const tagManagerArgs = {
//   gtmId: GTM_ID,
//   dataLayer: {
//     userID: 'null',
//     page: window.location.pathname,
//     event: 'conversion',
//     'send_to': 'AW-16783651576/K9KYCJ7NkewZEPjliMM-',
//     'value': 1.0,
//     'currency': 'USD'
//   }
// }

// // Initialize Google Tag Manager immediately
// TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Toast>
                <MixpanelInit>
                    <App />
                    <CookieConsent
                        buttonText="Accept"
                        style={{
                            background: "#0EBC5F",
                        }}
                        buttonStyle={{
                            fontWeight: "bold",
                            backgroundColor: "#77f0a1",
                            color: "#000",
                            borderRadius: 1000,
                        }}
                        onAccept={() => {
                            ReactGA.initialize(GA_TRACKING_ID);
                            
                            ReactGA.send({
                                hitType: "pageview",
                                page: window.location.pathname,
                            });
                            // TagManager.dataLayer({
                            //     dataLayer: {
                            //         event: 'conversion',
                            //         'send_to': 'AW-16783651576/K9KYCJ7NkewZEPjliMM-',
                            //         'value': 1.0,
                            //         'currency': 'USD'
                            //     }
                            // });
                        }}
                        onDecline={() => {
                            Cookies.remove("_ga");
                            Cookies.remove("_gat");
                            Cookies.remove("_gid");
                        }}
                    >
                        PathFinder uses cookies to enhance your user experience. Please
                        accept to help us on our mission!
                    </CookieConsent>
                </MixpanelInit>
            </Toast>
        </PersistGate>
    </Provider>
);

// Example usage of global logger
logger.info('Application rendered');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();