import React from 'react';
import './ChatCard.css';

export type ChatCardProps = {
    message: string;
    className?: string;
}

const ChatCard: React.FC<ChatCardProps> = ({ message, className }) => {
    return (
        <div className={`chat-card ${className}`}>
            <p>{message}</p>
        </div>
    )
}

export default ChatCard;
